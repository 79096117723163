<template>
  <section>
    <v-form>
      <v-row>
        <v-col cols="12"> <h1 class="text-bold">Add Notification</h1> </v-col>
        <v-col cols="12" class="pb-0">
          <v-label>Title</v-label>
          <v-text-field
            v-model="title"
            outlined
            placeholder="Title"
            class="mt-2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0">
          <v-label>Body</v-label>
          <v-textarea
            v-model="body"
            outlined
            placeholder="Description"
            class="mt-2"
          ></v-textarea>
        </v-col>

        <v-col cols="12">
          <v-btn @click="addNotification" :loading="loading" class="primary">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddNotification",
  data: () => ({
    title: undefined,
    body: undefined,
    loading: false,
  }),
  methods: {
    ...mapActions(["createNotification"]),
    async addNotification() {
      this.loading = true;
      await this.createNotification({
        title: this.title,
        body: this.body,
      });
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["allChallenges"]),
  },
  async created() {
    this.loading = false;
  },
};
</script>

<style>
</style>